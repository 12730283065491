import React from "react";
import { TextField, Grid } from "@mui/material";

const Step2 = ({ formData, handleChange, handleFileChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          name="targetEquipmentDescription"
          label="Target Equipment Description"
          value={formData.targetEquipmentDescription}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="width"
          label="Width (in)"
          type="number"
          value={formData.width}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="height"
          label="Height (in)"
          type="number"
          value={formData.height}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="depth"
          label="Depth (in)"
          type="number"
          value={formData.depth}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="weight"
          label="Weight (lbs)"
          type="number"
          value={formData.weight}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <input
          type="file"
          name="approximateRepresentation"
          accept="image/*"
          onChange={handleFileChange}
        />
      </Grid>
    </Grid>
  );
};

export default Step2;
