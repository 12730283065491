import React from "react";
import { TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";

const Step8 = ({ formData, handleChange, handleFileChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="isolatedCircuitProvided"
              checked={formData.isolatedCircuitProvided}
              onChange={handleChange}
            />
          }
          label="Isolated Circuit Provided"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="placementToElectricalAccess"
          label="Placement to Electrical Access (in)"
          type="number"
          value={formData.placementToElectricalAccess}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="placementToNetworkAccess"
          label="Placement to Network Access (in)"
          type="number"
          value={formData.placementToNetworkAccess}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <input
          type="file"
          name="electricalAccessPhoto"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="notes"
          label="Notes"
          value={formData.notes}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default Step8;
