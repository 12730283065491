import React, { useState } from "react";
import { Button, Typography, Container } from "@mui/material";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import axios from "axios";

const FormWizard = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    scheduledOn: "",
    customer: "",
    siteContact: "",
    siteName: "",
    address: "",
    specialInstructions: "",
    clientProjectManager: "",
    targetEquipmentDescription: "",
    width: "",
    height: "",
    depth: "",
    weight: "",
    approximateRepresentation: null,
    storeFrontPictures: null,
    wirelessCarrier: "",
    signalStrength: "",
    truckAccessType: "",
    streetRestrictions: false,
    restrictionsDescription: "",
    liftgateRequired: false,
    notifySitePrior: false,
    notes: "",
    hasSidewalkAndRamp: false,
    hasStepsAndStairways: false,
    hasRearDoorClearance: false,
    rearDoorClearanceWidth: "",
    rearDoorPictures: null,
    hasFrontDoorClearance: false,
    frontDoorClearanceWidth: "",
    frontDoorPictures: null,
    installationFloor: "",
    floorAccess: "",
    stairPhoto: null,
    needToRemoveExistingEquipment: false,
    describeExistingEquipment: "",
    existingEquipmentPhoto: null,
    floorType: "",
    hasRaisedPlatform: false,
    isThereACounter: false,
    counterAreaClearance: false,
    floorToBottomOfCounter: "",
    distanceAcrossOpening: "",
    frontOfCounterToBackWall: "",
    counterAreaPhoto: null,
    tappedOffArea: null,
    tappedOffAreaWideAngle: null,
    isolatedCircuitProvided: false,
    placementToElectricalAccess: "",
    placementToNetworkAccess: "",
    electricalAccessPhoto: null,
    readyForInstall: false,
    siteContactPhoneNumber: "",
    siteContactSignature: "",
    installerName: "",
    checkInDate: "",
    checkInTime: "",
    checkOutDate: "",
    checkOutTime: ""
  });

  const handleChange = e => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handleFileChange = e => {
    const { name } = e.target;
    setFormData({ ...formData, [name]: e.target.files[0] });
  };

  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log("GPS Location:", position.coords);
        setFormData({
          ...formData,
          gpsLocation: `${position.coords.latitude}, ${position.coords.longitude}`
        });
      });
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData();

    for (let key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        "http://localhost:3001/submit-form",
        data
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Form Wizard
      </Typography>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <Step1 formData={formData} handleChange={handleChange} />
        )}
        {step === 2 && (
          <Step2
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
        )}
        {step === 3 && (
          <Step3
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            handleLocation={handleLocation}
          />
        )}
        {step === 4 && (
          <Step4 formData={formData} handleChange={handleChange} />
        )}
        {step === 5 && (
          <Step5
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
        )}
        {step === 6 && (
          <Step6
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
        )}
        {step === 7 && (
          <Step7
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
        )}
        {step === 8 && (
          <Step8
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
          />
        )}
        {step === 9 && (
          <Step9 formData={formData} handleChange={handleChange} />
        )}
        {step === 10 && (
          <Step10 formData={formData} handleChange={handleChange} />
        )}

        <div style={{ marginTop: 20 }}>
          {step > 1 && (
            <Button onClick={handleBack} variant="contained" color="secondary">
              Back
            </Button>
          )}
          {step < 10 ? (
            <Button
              onClick={handleNext}
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
            >
              Next
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
            >
              Submit
            </Button>
          )}
        </div>
      </form>
    </Container>
  );
};

export default FormWizard;
