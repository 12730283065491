import React from "react";
import { TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";

const Step9 = ({ formData, handleChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="readyForInstall"
              checked={formData.readyForInstall}
              onChange={handleChange}
            />
          }
          label="Ready for Install"
        />
      </Grid>
      {!formData.readyForInstall && (
        <Grid item xs={12}>
          <TextField
            name="notes"
            label="Notes"
            value={formData.notes}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            required
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          name="siteContact"
          label="Site Contact"
          value={formData.siteContact}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="siteContactPhoneNumber"
          label="Site Contact - Phone Number"
          value={formData.siteContactPhoneNumber}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="siteContactSignature"
          label="Site Contact Signature"
          value={formData.siteContactSignature}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="installerName"
          label="HamodaTech Installer/Trainer Name"
          value={formData.installerName}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default Step9;
