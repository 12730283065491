import React from "react";
import { TextField, Grid } from "@mui/material";

const Step1 = ({ formData, handleChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          name="scheduledOn"
          label="Scheduled On"
          type="date"
          value={formData.scheduledOn}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="customer"
          label="Customer"
          value={formData.customer}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="siteContact"
          label="Scheduled Site Contact"
          value={formData.siteContact}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="siteName"
          label="Site Name"
          value={formData.siteName}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="address"
          label="Address"
          value={formData.address}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="specialInstructions"
          label="Special Instructions"
          value={formData.specialInstructions}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="clientProjectManager"
          label="Client Project Manager"
          value={formData.clientProjectManager}
          onChange={handleChange}
          required
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default Step1;
