import React from "react";
import {
  TextField,
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel
} from "@mui/material";

const Step4 = ({ formData, handleChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          select
          name="truckAccessType"
          label="Truck Access Type"
          value={formData.truckAccessType}
          onChange={handleChange}
          required
          fullWidth
        >
          <MenuItem value="semi">Semi</MenuItem>
          <MenuItem value="utility">Utility (box)</MenuItem>
          <MenuItem value="pickup">Pickup</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="streetRestrictions"
              checked={formData.streetRestrictions}
              onChange={handleChange}
            />
          }
          label="Street Restrictions"
        />
      </Grid>
      {formData.streetRestrictions && (
        <Grid item xs={12}>
          <TextField
            name="restrictionsDescription"
            label="Restrictions Description"
            value={formData.restrictionsDescription}
            onChange={handleChange}
            required
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="liftgateRequired"
              checked={formData.liftgateRequired}
              onChange={handleChange}
            />
          }
          label="Liftgate Required"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="notifySitePrior"
              checked={formData.notifySitePrior}
              onChange={handleChange}
            />
          }
          label="Notify site prior to equipment delivery"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="notes"
          label="Notes"
          value={formData.notes}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default Step4;
