import React from "react";
import {
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem
} from "@mui/material";

const Step5 = ({ formData, handleChange, handleFileChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="hasSidewalkAndRamp"
              checked={formData.hasSidewalkAndRamp}
              onChange={handleChange}
            />
          }
          label="Has Sidewalk and Handicap Ramp"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="hasStepsAndStairways"
              checked={formData.hasStepsAndStairways}
              onChange={handleChange}
            />
          }
          label="Has Steps and Stairways"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="hasRearDoorClearance"
              checked={formData.hasRearDoorClearance}
              onChange={handleChange}
            />
          }
          label="Has Rear Door Clearance"
        />
      </Grid>
      {formData.hasRearDoorClearance && (
        <Grid item xs={12}>
          <TextField
            name="rearDoorClearanceWidth"
            label="Rear Door Clearance Width (in)"
            type="number"
            value={formData.rearDoorClearanceWidth}
            onChange={handleChange}
            required
            fullWidth
          />
          <input
            type="file"
            name="rearDoorPictures"
            accept="image/*"
            onChange={handleFileChange}
            required
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="hasFrontDoorClearance"
              checked={formData.hasFrontDoorClearance}
              onChange={handleChange}
            />
          }
          label="Has Front Door Clearance"
        />
      </Grid>
      {formData.hasFrontDoorClearance && (
        <Grid item xs={12}>
          <TextField
            name="frontDoorClearanceWidth"
            label="Front Door Clearance Width (in)"
            type="number"
            value={formData.frontDoorClearanceWidth}
            onChange={handleChange}
            required
            fullWidth
          />
          <input
            type="file"
            name="frontDoorPictures"
            accept="image/*"
            onChange={handleFileChange}
            required
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          select
          name="installationFloor"
          label="Installation Floor"
          value={formData.installationFloor}
          onChange={handleChange}
          required
          fullWidth
        >
          {[1, 2, 3, 4, 5].map(floor => (
            <MenuItem key={floor} value={floor}>
              {floor}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          name="floorAccess"
          label="Floor Access"
          value={formData.floorAccess}
          onChange={handleChange}
          required
          fullWidth
        >
          <MenuItem value="stairs">Stairs</MenuItem>
          <MenuItem value="elevator">Elevator</MenuItem>
          <MenuItem value="both">Both</MenuItem>
        </TextField>
      </Grid>
      {formData.floorAccess === "stairs" && (
        <Grid item xs={12}>
          <input
            type="file"
            name="stairPhoto"
            accept="image/*"
            onChange={handleFileChange}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          name="notes"
          label="Notes"
          value={formData.notes}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default Step5;
