import React from "react";
import {
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem
} from "@mui/material";

const Step7 = ({ formData, handleChange, handleFileChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          select
          name="floorType"
          label="Floor Type"
          value={formData.floorType}
          onChange={handleChange}
          required
          fullWidth
        >
          <MenuItem value="wood">Wood</MenuItem>
          <MenuItem value="laminate">Laminate</MenuItem>
          <MenuItem value="concrete">Concrete</MenuItem>
          <MenuItem value="tile">Tile</MenuItem>
          <MenuItem value="carpet">Carpet</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="hasRaisedPlatform"
              checked={formData.hasRaisedPlatform}
              onChange={handleChange}
            />
          }
          label="Has Raised Platform"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="isThereACounter"
              checked={formData.isThereACounter}
              onChange={handleChange}
            />
          }
          label="Is there a Counter"
        />
      </Grid>
      {formData.isThereACounter && (
        <>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="counterAreaClearance"
                  checked={formData.counterAreaClearance}
                  onChange={handleChange}
                />
              }
              label="Counter Area Clearance"
            />
          </Grid>
          {formData.counterAreaClearance && (
            <>
              <Grid item xs={12}>
                <TextField
                  name="floorToBottomOfCounter"
                  label="Floor to Bottom of Counter (in)"
                  type="number"
                  value={formData.floorToBottomOfCounter}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="distanceAcrossOpening"
                  label="Distance Across Opening (in)"
                  type="number"
                  value={formData.distanceAcrossOpening}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="frontOfCounterToBackWall"
                  label="Front of Counter to Back Wall (in)"
                  type="number"
                  value={formData.frontOfCounterToBackWall}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <input
              type="file"
              name="counterAreaPhoto"
              accept="image/*"
              onChange={handleFileChange}
              required
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <input
          type="file"
          name="tappedOffArea"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <input
          type="file"
          name="tappedOffAreaWideAngle"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="notes"
          label="Notes"
          value={formData.notes}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default Step7;
