import React from "react";
import { TextField, Grid, MenuItem, Button } from "@mui/material";

const Step3 = ({
  formData,
  handleChange,
  handleFileChange,
  handleLocation
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <input
          type="file"
          name="storeFrontPictures"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          name="wirelessCarrier"
          label="Wireless Carrier"
          value={formData.wirelessCarrier}
          onChange={handleChange}
          required
          fullWidth
        >
          <MenuItem value="AT&T">AT&T</MenuItem>
          <MenuItem value="Verizon">Verizon</MenuItem>
          <MenuItem value="Tmobile">Tmobile</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          select
          name="signalStrength"
          label="Signal Strength (bars inside facility)"
          value={formData.signalStrength}
          onChange={handleChange}
          required
          fullWidth
        >
          {[1, 2, 3, 4, 5].map(bars => (
            <MenuItem key={bars} value={bars}>
              {bars}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={handleLocation} fullWidth>
          Get GPS Location
        </Button>
      </Grid>
    </Grid>
  );
};

export default Step3;
