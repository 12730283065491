import React from "react";
import { TextField, Grid, Checkbox, FormControlLabel } from "@mui/material";

const Step6 = ({ formData, handleChange, handleFileChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="needToRemoveExistingEquipment"
              checked={formData.needToRemoveExistingEquipment}
              onChange={handleChange}
            />
          }
          label="Need to Remove Existing Equipment"
        />
      </Grid>
      {formData.needToRemoveExistingEquipment && (
        <>
          <Grid item xs={12}>
            <TextField
              name="describeExistingEquipment"
              label="Describe Existing Equipment"
              value={formData.describeExistingEquipment}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              name="existingEquipmentPhoto"
              accept="image/*"
              onChange={handleFileChange}
              required
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Step6;
