import React from "react";
import { TextField, Grid } from "@mui/material";

const Step10 = ({ formData, handleChange }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          name="checkInDate"
          label="Check-in Date"
          type="date"
          value={formData.checkInDate}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="checkInTime"
          label="Check-in Time"
          type="time"
          value={formData.checkInTime}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="checkOutDate"
          label="Check-out Date"
          type="date"
          value={formData.checkOutDate}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="checkOutTime"
          label="Check-out Time"
          type="time"
          value={formData.checkOutTime}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Step10;
